export const propertyNames = [
    'model',
    'size',
    'ratio',
    'assemblyPosition',
    'faceSize',
    'mountBase',
    'outFlange',
] as const;

export type PropertyName = typeof propertyNames[number];

export type Cad = {
    dwg : string | null;
    step : string | null;
};

export type StockCard = {
    id : string;
    productLineCode : string;
    model : string;
    size : string | null;
    ratio : string;
    assemblyPosition : string | null;
    faceSize : string | null;
    mountBase : string | null;
    outFlange : string | null;
    cad : Cad;
};

export type StockCardProperties = Omit<StockCard, 'id' | 'productLineCode' | 'cad'>;
