import blwgsrdrIcon from '@/assets/products/blwgsrdr.png';
import blwgsrsrIcon from '@/assets/products/blwgsrsr.png';
import {Box, Typography} from '@mui/material';
import hgsrrpIcon from '@/assets/products/hgsrrp.png';
import {RhfAutocomplete} from 'mui-rhf-integration';
import hgsrs0103Icon from '@/assets/products/hgsrs0103.png';
import type {Control} from 'react-hook-form';
import hgsrsaaIcon from '@/assets/products/hgsrsaa.png';
import hgsrsacIcon from '@/assets/products/hgsrsac.png';
import ilwgsrdrIcon from '@/assets/products/ilwgsrdr.png';
import ilwgsrsrIcon from '@/assets/products/ilwgsrsr.png';
import type {ConfiguratorFieldValues} from '@/pages/Configurator/Configurator';

type Props = {
    control : Control<ConfiguratorFieldValues>;
};

export const productLineOptions = [
    {
        value: 'ILWGSRSR',
        label: 'I Line Worm Gear Speed Reducers (Single Reduction)',
        icon: ilwgsrsrIcon,
    },
    {
        value: 'ILWGSRDR',
        label: 'I Line Worm Gear Speed Reducers (Double Reduction)',
        icon: ilwgsrdrIcon,
    },
    {
        value: 'BLWGSRSR',
        label: 'Blue Line Worm Gear Speed Reducers (Single Reduction)',
        icon: blwgsrsrIcon,
    },
    {
        value: 'BLWGSRDR',
        label: 'Blue Line Worm Gear Speed Reducers (Double Reduction)',
        icon: blwgsrdrIcon,
    },
    {
        value: 'HGSRSAA',
        label: 'Helical Gear Speed Reducers (Size AA)',
        icon: hgsrsaaIcon,
    },
    {
        value: 'HGSRS0103',
        label: 'Helical Gear Speed Reducers (Size 01-03)',
        icon: hgsrs0103Icon,
    },
    {
        value: 'HGSRSAC',
        label: 'Helical Gear Speed Reducers (Size A-C)',
        icon: hgsrsacIcon,
    },
    {
        value: 'HGSRRP',
        label: 'Helical Gear Speed Reducers (Ratio-Pak)',
        icon: hgsrrpIcon,
    },
];

const ProductLineSelect = ({control} : Props) : JSX.Element => {
    return (
        <RhfAutocomplete
            textFieldProps={{
                label: 'Product Line',
            }}
            options={productLineOptions}
            control={control}
            name="productLineCode"
            disableClearable
            optionToValue={option => option.value}
            valueToOption={value => productLineOptions.find(option => option.value === value) ?? null}
            renderOption={(props, option) => (
                <Box component="li" {...props} sx={{display: 'flex'}}>
                    <Box
                        component="img"
                        width={32}
                        src={option.icon}
                        sx={{border: '1px solid black', marginRight: 1}}
                    />
                    <Typography>
                        {option.label}
                    </Typography>
                </Box>
            )}
        />
    );
};

export default ProductLineSelect;
