import type {Theme} from '@mui/material';
import {AppBar, Box, Button, Container, Toolbar, useMediaQuery} from '@mui/material';
import {Outlet} from 'react-router-dom';
import logoShort from './assets/logo-short.png';
import logo from './assets/logo.png';

const Layout = () : JSX.Element => {
    const useShortLogo = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    return (
        <>
            <AppBar position="static" sx={{
                backgroundColor: '#191919',
                color: 'rgba(252, 252,252, 0.6)',
            }}>
                <Toolbar>
                    <Box sx={{display: 'flex', verticalAlign: 'center', flexGrow: 1}}>
                        <img src={useShortLogo ? logoShort : logo} height={48}/>
                    </Box>
                    <Button color="inherit" href="https://iptsinc.com/">
                        Home
                    </Button>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth={false} sx={{marginTop: 4, marginBottom: 4}}>
                <Outlet/>
            </Container>
        </>
    );
};

export default Layout;
