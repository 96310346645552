import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import * as React from 'react';

type Props = {
    productLineCode : string;
    fileType : 'DWG' | 'STEP';
    fileLink : string;
};

const ViewOrDownloadButtonGroup = ({productLineCode, fileType, fileLink} : Props) : JSX.Element => {
    return (
        <>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                    component="a"
                    href={`https://sharecad.org/cadframe/load?url=${fileLink}`}
                    target="_blank"
                >
                    View {fileType}
                </Button>
                <Button
                    component="a"
                    download={`${productLineCode}_CAD_Preview.${fileType.toLowerCase()}`}
                    href={fileLink}
                    target="_blank"
                >
                    <FileDownloadIcon/>
                </Button>
            </ButtonGroup>
        </>
    );
};

export default ViewOrDownloadButtonGroup;
