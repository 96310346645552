import useHandleMutation from '@/hooks/useHandleMutation';
import type {CreateQuoteValues} from '@/mutations/quote';
import {zodResolver} from '@hookform/resolvers/zod';
import {useCreateQuoteMutation} from '@/mutations/quote';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack} from '@mui/material';
import type {ConfiguratorFieldValues} from '@/pages/Configurator/Configurator';
import Typography from '@mui/material/Typography';
import AddressFieldset from '@/pages/Configurator/QuoteDialog/AddressFieldset';
import {RhfCheckbox, RhfTextField} from 'mui-rhf-integration';
import CancelDialog from '@/pages/Configurator/QuoteDialog/CancelDialog';
import {useCallback, useEffect, useState} from 'react';
import SuccessDialog from '@/pages/Configurator/QuoteDialog/SuccessDialog';
import {Controller, useForm} from 'react-hook-form';
import type {Cad} from '@/types/stock-card';
import {z} from 'zod';
import {normalizeNone} from '@/utils/stock-card-property';
import {errorMap} from '@/utils/zod';

const address = z.object({
    addressLineOne: z.string().trim().min(1),
    addressLineTwo: z.string().trim(),
    city: z.string().trim().min(1),
    state: z.string().trim().min(1),
    zipCode: z.string().trim().min(1),
});

const quoteSchema = z.object({
    productLineCode: z.string(),
    quantity: z.string().regex(/^[1-9]\d*$/),
    contact: z.object({
        name: z.string().trim().min(1),
        emailAddress: z.string().email(),
    }),
    companyName: z.string().trim().min(1),
    billingAddress: address,
    sameShippingAndBilling: z.boolean(),
    shippingAddress: address.optional(),
    horsepowerSpecifications: z.string().trim(),
    torqueSpecifications: z.string().trim(),
    additionalInformation: z.string().trim(),
});

export type QuoteFieldValues = z.infer<typeof quoteSchema>;

type Props = {
    stockCardCode : string;
    configuratorFieldValues : ConfiguratorFieldValues;
    locked : boolean;
    open : boolean;
    onClose : () => void;
    onQuoteSubmitSuccess : (isLocked : boolean) => void;
    setPreviewLink : (previewLink : Cad | null) => void;
};

const QuoteDialog = ({
    stockCardCode,
    configuratorFieldValues,
    locked,
    open,
    onClose,
    onQuoteSubmitSuccess,
    setPreviewLink,
} : Props) : JSX.Element => {
    const createQuoteSubmissionMutation = useCreateQuoteMutation();
    const handleMutation = useHandleMutation();
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const defaultValues : Partial<QuoteFieldValues> = {
        productLineCode: stockCardCode,
        quantity: '1',
        sameShippingAndBilling: true,
        billingAddress: {
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            zipCode: '',
        },
        horsepowerSpecifications: '',
        torqueSpecifications: '',
        additionalInformation: '',
    };

    const form = useForm<QuoteFieldValues>({
        resolver: zodResolver(quoteSchema, {errorMap}),
        defaultValues: defaultValues,
    });

    useEffect(() => {
        form.reset(defaultValues);
    }, [form.reset, stockCardCode]);

    useEffect(() => {
        if (!open) {
            form.reset(defaultValues);
        }
    }, [form.reset, open]);

    useEffect(() => {
        const subscription = form.watch((value, {name}) => {
            if (name !== 'sameShippingAndBilling') {
                return;
            }

            if (value.sameShippingAndBilling) {
                form.resetField('shippingAddress');
                form.clearErrors('shippingAddress');
                return;
            }

            form.setValue('shippingAddress', {
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                zipCode: '',
            });
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [form.watch, form.resetField, form.clearErrors]);

    const handleSubmit = useCallback(async (values : QuoteFieldValues) => {
        const {sameShippingAndBilling, ...rest} = values;
        const quoteValues : CreateQuoteValues = {
            ...rest,
            quantity: parseInt(values.quantity, 10),
            submissionType: locked ? 'quote' : 'stock-card',
            productProperties: {
                model: configuratorFieldValues.model,
                size: normalizeNone(configuratorFieldValues.size),
                ratio: configuratorFieldValues.ratio,
                assemblyPosition: normalizeNone(configuratorFieldValues.assemblyPosition),
                faceSize: normalizeNone(configuratorFieldValues.faceSize),
                mountBase: normalizeNone(configuratorFieldValues.mountBase),
                outFlange: normalizeNone(configuratorFieldValues.outFlange),
            },
        };

        if ((await handleMutation(createQuoteSubmissionMutation, quoteValues)).success) {
            setSuccessDialogOpen(true);
            onQuoteSubmitSuccess(locked);
            setPreviewLink(null);
        }
    }, [configuratorFieldValues, locked]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!form.formState.isSubmitting) {
                    setCancelDialogOpen(true);
                }
            }}
            maxWidth="lg"
            fullWidth
        >
            <Box
                component="form"
                onSubmit={form.handleSubmit(handleSubmit)}
                sx={{overflowY: 'auto', display: 'flex', flexDirection: 'column'}}
            >
                <DialogTitle>Request a {locked ? 'Quote' : 'Stock Card'}</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}>
                        <Stack spacing={2} flexGrow={1} flexBasis={0}>
                            <Stack spacing={2} direction="row">
                                <RhfTextField
                                    sx={{flexGrow: '1'}}
                                    control={form.control}
                                    label="Product Line Code"
                                    name="productLineCode"
                                    disabled={true}
                                    InputProps={{readOnly: true}}
                                />
                                <RhfTextField
                                    sx={{width: '100px'}}
                                    control={form.control}
                                    label="Quantity"
                                    name="quantity"
                                />
                            </Stack>
                            <RhfTextField
                                control={form.control}
                                label="Contact Name"
                                name="contact.name"
                            />
                            <RhfTextField
                                control={form.control}
                                label="Contact Email"
                                name="contact.emailAddress"
                            />
                            <RhfTextField
                                control={form.control}
                                label="Company Name"
                                name="companyName"
                            />
                            <Typography>Billing Address</Typography>
                            <AddressFieldset prefix="billingAddress" form={form}/>
                        </Stack>
                        <Stack spacing={2} flexGrow={1} flexBasis={0}>
                            <Typography>Shipping Address</Typography>
                            <FormControlLabel
                                control={
                                    <RhfCheckbox
                                        control={form.control}
                                        name="sameShippingAndBilling"
                                    />
                                }
                                label="Shipping Address Same as Billing Address"
                            />
                            <Controller control={form.control} name="sameShippingAndBilling" render={({field}) => {
                                if (!field.value) {
                                    return <AddressFieldset prefix="shippingAddress" form={form}/>;
                                }

                                return <></>;
                            }}/>
                            <RhfTextField
                                control={form.control}
                                label="Horsepower Specifications"
                                name="horsepowerSpecifications"
                                multiline
                                rows={5}
                            />
                            <RhfTextField
                                control={form.control}
                                label="Torque Specifications"
                                name="torqueSpecifications"
                                multiline
                                rows={5}
                            />
                            <RhfTextField
                                control={form.control}
                                label="Additional Information / Questions"
                                name="additionalInformation"
                                multiline
                                rows={5}
                            />
                            {!locked && (
                                <Typography sx={{color: 'red'}}>
                                    This product configuration does not exist. Request a stock card.
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => {
                        setCancelDialogOpen(true);
                    }}>
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary">Request {locked ? 'Quote' : 'Stock Card'}</Button>
                </DialogActions>
            </Box>
            <SuccessDialog
                open={successDialogOpen}
                onClose={() => {
                    setSuccessDialogOpen(false);
                }}
                closeQuoteDialog={() => {
                    onClose();
                }}
            />
            <CancelDialog
                isLocked={locked}
                open={cancelDialogOpen}
                onClose={() => {
                    setCancelDialogOpen(false);
                }}
                closeQuoteDialog={() => {
                    onClose();
                }}
            />
        </Dialog>
    );
};

export default QuoteDialog;
