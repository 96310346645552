import type {UseFormReturn} from 'react-hook-form';
import type {ConfiguratorFieldValues} from './Configurator';
import type {PropertyName, Cad} from '@/types/stock-card';
import PropertySelect from './PropertySelect';
import {propertyNames} from '@/types/stock-card';

type Props = {
    productLineCode : string;
    form : UseFormReturn<ConfiguratorFieldValues>;
    unlocked : boolean;
    previewLink : Cad | null;
    setPreviewLink : (previewLink : Cad | null) => void;
};

const propertyLabels : Record<PropertyName, string> = {
    model: 'Model',
    size: 'Size',
    ratio: 'Ratio',
    assemblyPosition: 'Assembly Position',
    faceSize: 'Face Size',
    mountBase: 'Mount Base',
    outFlange: 'Out Flange',
};

const PropertySelects = ({productLineCode, form, unlocked, previewLink, setPreviewLink} : Props) : JSX.Element => {
    const values = form.watch() as Partial<ConfiguratorFieldValues>;
    const selects = [];
    let previousValue : string | null | undefined = null;

    for (const property of propertyNames) {
        if (!unlocked && previousValue === undefined) {
            break;
        }

        previousValue = values[property];

        selects.push(
            <PropertySelect
                key={property}
                label={propertyLabels[property]}
                productLineCode={productLineCode}
                unlocked={unlocked}
                selectedProperties={values}
                name={property}
                form={form}
                previewLink={previewLink}
                setPreviewLink={setPreviewLink}
            />
        );
    }

    return (
        <>{selects}</>
    );
};

export default PropertySelects;
