import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import type {PropertyName, StockCard, StockCardProperties} from '@/types/stock-card';
import {propertyNames} from '@/types/stock-card';
import {apiUrl} from '@/utils/api';
import {isSome} from '@/utils/stock-card-property';

export const useStockCardPropertyValuesQuery = (
    productLineCode : string,
    property : PropertyName,
    unlocked : boolean,
    selectedProperties : Partial<Record<PropertyName, string | null>>,
) : UseQueryResult<Array<string | null>> => {
    const queryKey = ['stock-card', 'property', property, unlocked ? 'unlocked' : 'locked', productLineCode];
    const url = apiUrl('/stock-cards/values');
    url.searchParams.set('productLineCode', productLineCode);
    url.searchParams.set('property', property);

    if (!unlocked) {
        url.searchParams.set('allowNulls', 'true');

        for (const current of propertyNames) {
            if (current === property) {
                break;
            }

            queryKey.push(current);
            const value = selectedProperties[current];

            if (!value) {
                queryKey.push('');
                continue;
            }

            queryKey.push(value);
            url.searchParams.set(current, value);
        }
    }

    return useQuery(queryKey, async ({signal}) => {
        const response = await fetch(url.toString(), {signal});

        if (!response.ok) {
            throw new Error(`Unable to fetch values for product line ${productLineCode} and property ${property}`);
        }

        return await response.json() as string[];
    });
};

export const useStockCardLookupQuery = (
    properties : StockCardProperties,
    productLineCode : string
) : UseQueryResult<StockCard> => {
    const queryKey = ['stock-card', 'lookup'];
    const url = apiUrl('/stock-cards/lookup');
    url.searchParams.set('productLineCode', productLineCode);

    for (const property of propertyNames) {
        const value = properties[property] ? properties[property] : null;

        if (isSome(value)) {
            queryKey.push(property);
            queryKey.push(value);
            url.searchParams.set(property, value);
        }
    }

    return useQuery(queryKey, async ({signal}) => {
        const response = await fetch(url.toString(), {signal});

        if (response.status === 404) {
            return null;
        }

        if (!response.ok) {
            throw new Error(`Could not lookup stock card for product code ${productLineCode}`);
        }

        return await response.json() as StockCard;
    });
};
