import {Divider, Stack} from '@mui/material';
import ViewOrDownloadButtonGroup from '@/pages/Preview/ViewOrDownloadButtonGroup';
import {useStockCardLookupQuery} from '@/queries/stock-card';
import type {StockCardProperties, Cad} from '@/types/stock-card';

type Props = {
    properties : StockCardProperties;
    productLineCode : string;
    setPreviewLink : (previewLink : Cad | null) => void;
};

const StockCard = ({properties, productLineCode, setPreviewLink} : Props) : JSX.Element | null => {
    const stockCardQuery = useStockCardLookupQuery(properties, productLineCode);

    if (!stockCardQuery.data) {
        return null;
    }

    const matchingStockCard = stockCardQuery.data;

    if (matchingStockCard.cad.step || matchingStockCard.cad.dwg) {
        setPreviewLink(matchingStockCard.cad);
    }

    return (
        <>
            <Divider/>
            <Stack spacing={2} direction="row">
                {matchingStockCard.cad.dwg && (
                    <ViewOrDownloadButtonGroup
                        productLineCode={productLineCode}
                        fileType="DWG"
                        fileLink={matchingStockCard.cad.dwg}
                    />
                )}
                {matchingStockCard.cad.step && (
                    <ViewOrDownloadButtonGroup
                        productLineCode={productLineCode}
                        fileType="STEP"
                        fileLink={matchingStockCard.cad.step}
                    />
                )}
            </Stack>
        </>
    );
};

export default StockCard;
