import {Divider, Grid, Link, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import type {RouteParams} from '@/pages/Configurator/Configurator';
import Configurator from '@/pages/Configurator/Configurator';
import ViewOrDownloadButtonGroup from '@/pages/Preview/ViewOrDownloadButtonGroup';
import type {Cad} from '@/types/stock-card';

const Home = () : JSX.Element => {
    const [previewLink, setPreviewLink] = useState<Cad | null>(null);
    const {productLineCode} = useParams<RouteParams>();
    return (
        <Grid container>
            <Grid item xs={0} sm={previewLink ? 0 : 3}/>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>Product Configurator</Typography>
                <Typography paragraph>
                    This tool will allow you to request a quote and download 2D or 3D CAD files for IPTS products.
                    Begin by selecting the type of product from the product line drop-down menu.
                    Continue by selecting the Model, Size, Ratio, Assembly Position, C-Face Size and other options
                    from the respective drop-down menus until there are no selections remaining.
                </Typography>
                <Typography paragraph>
                    Not familiar with the IPTS product numbering or unsure which reducer you
                    need? <Link href="mailto:sales@iptsinc.com">Contact us</Link> and we will be happy to assist you.
                </Typography>
                <Divider sx={{mb: 3}}/>
                <Configurator previewLink={previewLink} setPreviewLink={setPreviewLink}/>
            </Grid>
            <Grid item xs={12} sm={6} sx={{p: 2}}>
                {previewLink
                    ? (
                        <>
                            <Typography variant="h6" textAlign="center" gutterBottom>Product Preview</Typography>
                            <img
                                width="100%"
                                src={
                                    previewLink.dwg
                                        ? previewLink.dwg.replaceAll('DWG', 'PNG')
                                        : previewLink.step?.replaceAll('STP', 'PNG')
                                }
                                alt="Cad file preview image"
                            />
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                                {previewLink.dwg && (
                                    <ViewOrDownloadButtonGroup
                                        productLineCode={productLineCode ?? ''}
                                        fileType="DWG"
                                        fileLink={previewLink.dwg}
                                    />
                                )}
                                {previewLink.step && (
                                    <ViewOrDownloadButtonGroup
                                        productLineCode={productLineCode ?? ''}
                                        fileType="STEP"
                                        fileLink={previewLink.step}
                                    />
                                )}
                            </Stack>
                        </>
                    )
                    : <Grid item xs={0} sm={3}/>
                }
            </Grid>
        </Grid>
    );
};

export default Home;
