import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

type Props = {
    isLocked : boolean;
    open : boolean;
    onClose : () => void;
    closeQuoteDialog : () => void;
};

const CancelDialog = ({isLocked, open, onClose, closeQuoteDialog} : Props) : JSX.Element => {
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Cancel your {isLocked ? 'quote' : 'stock card'} request?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    By canceling you will lose any information that you have already entered.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => {
                    onClose();
                }}>
                    No
                </Button>
                <Button color="secondary" onClick={() => {
                    closeQuoteDialog();
                    onClose();
                }}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancelDialog;
