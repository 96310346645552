import type {UseFormReturn} from 'react-hook-form';
import type {ConfiguratorFieldValues} from '@/pages/Configurator/Configurator';
import StockCard from '@/pages/Preview/StockCard';
import type {StockCardProperties, Cad} from '@/types/stock-card';
import {propertyNames} from '@/types/stock-card';

type Props = {
    productLineCode : string;
    setPreviewLink : (previewLink : Cad | null) => void;
    form : UseFormReturn<ConfiguratorFieldValues>;
};

const isAnyPropertyMissing = (values : Partial<ConfiguratorFieldValues>) : values is StockCardProperties => {
    return propertyNames.some(property => values[property] === undefined);
};

const StockCardLookup = ({productLineCode, setPreviewLink, form} : Props) : JSX.Element | null => {
    const properties = form.watch() as Partial<ConfiguratorFieldValues>;

    if (isAnyPropertyMissing(properties)) {
        return null;
    }

    return (
        <StockCard
            properties={properties as StockCardProperties}
            productLineCode={productLineCode}
            setPreviewLink={setPreviewLink}
        />
    );
};

export default StockCardLookup;
