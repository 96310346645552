import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

type Props = {
    open : boolean;
    onClose : () => void;
    closeQuoteDialog : () => void;
};

const SuccessDialog = ({open, onClose, closeQuoteDialog} : Props) : JSX.Element => {
    return (
        <Dialog
            open={open}
            onClose={() => {
                closeQuoteDialog();
                onClose();
            }}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>Request Submitted</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Thank you for your submission. You will receive an email confirmation to the address
                    provided within a few minutes. You will be contacted by the IPTS team at the address provided
                    upon review of your submission.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        closeQuoteDialog();
                        onClose();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessDialog;
