import {zodResolver} from '@hookform/resolvers/zod';
import {Alert, Box, Button, Divider, Stack} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import StockCardLookup from '@/pages/Preview/StockCardLookup';
import {useNavigate, useParams} from 'react-router-dom';
import type {Cad} from '@/types/stock-card';
import {z} from 'zod';
import {propertyNames} from '@/types/stock-card';
import {isSome} from '@/utils/stock-card-property';
import {errorMap} from '@/utils/zod';
import LockedSwitch from './LockedSwitch';
import ProductLineSelect, {productLineOptions} from './ProductLineSelect';
import PropertySelects from './PropertySelects';
import QuoteDialog from './QuoteDialog';

const configuratorSchema = z.object({
    productLineCode: z.string(),
    locked: z.boolean(),
    model: z.string(),
    size: z.string().nullable(),
    ratio: z.string(),
    assemblyPosition: z.string().nullable(),
    faceSize: z.string().nullable(),
    mountBase: z.string().nullable(),
    outFlange: z.string().nullable(),
});

const unsupportedProductLineCodes = new Set(['ILWGSRDR', 'BLWGSRDR']);

export type ConfiguratorFieldValues = z.infer<typeof configuratorSchema>;

export type RouteParams = {
    productLineCode ?: string;
};

type Props = {
    previewLink : Cad | null;
    setPreviewLink : (previewLink : Cad | null) => void;
};

const Configurator = ({previewLink, setPreviewLink} : Props) : JSX.Element => {
    const {productLineCode} = useParams<RouteParams>();
    const navigate = useNavigate();
    const [stockCardCode, setStockCardCode] = useState<string | null>(null);
    const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);

    useEffect(() => {
        if (!productLineOptions.some(option => option.value === productLineCode)) {
            navigate('/');
        }
    }, [productLineCode, navigate]);

    const form = useForm<ConfiguratorFieldValues>({
        resolver: zodResolver(configuratorSchema, {errorMap}),
        defaultValues: {
            productLineCode: productLineCode
                ? productLineOptions.some(option => option.value === productLineCode) ? productLineCode : undefined
                : undefined,
            locked: true,
        },
    });
    const watchProductLineCode = form.watch('productLineCode') as string | undefined;
    const watchLocked = form.watch('locked');

    const onQuoteSubmitSuccess = (isLocked : boolean) => {
        form.reset();

        if (!isLocked) {
            form.setValue('locked', false);
        }
    };

    useEffect(() => {
        const subscription = form.watch((value, {name}) => {
            if (name === 'productLineCode') {
                navigate(value.productLineCode ? `/${value.productLineCode}` : '/');

                for (const property of propertyNames) {
                    form.resetField(property);
                    form.clearErrors(property);
                }
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [form.watch, form.resetField, form.clearErrors]);

    const configuratorAvailable = watchProductLineCode
        ? !unsupportedProductLineCodes.has(watchProductLineCode)
        : true;

    const handleSubmit = useCallback((values : ConfiguratorFieldValues) => {
        setStockCardCode(propertyNames.map(property => values[property]).filter(value => isSome(value)).join('-'));
        setQuoteDialogOpen(true);
    }, []);

    return (
        <>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Stack spacing={2}>
                    <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}>
                        <Box sx={{flexGrow: 1}}>
                            <ProductLineSelect control={form.control}/>
                        </Box>
                        <LockedSwitch form={form} disabled={!watchProductLineCode}/>
                    </Stack>

                    {watchProductLineCode && configuratorAvailable && (
                        <PropertySelects
                            productLineCode={watchProductLineCode}
                            form={form}
                            unlocked={!watchLocked}
                            previewLink={previewLink}
                            setPreviewLink={setPreviewLink}
                        />
                    )}

                    {productLineCode && (
                        <StockCardLookup productLineCode={productLineCode} setPreviewLink={setPreviewLink} form={form}/>
                    )}

                    <Divider/>

                    {configuratorAvailable && (
                        <Button type="submit" variant="contained">
                            Request a {watchLocked ? 'Quote' : 'Stock Card'}
                        </Button>
                    )}

                    {!configuratorAvailable && (
                        <Alert severity="error">Product configuration is not available for this product.</Alert>
                    )}
                </Stack>
            </form>
            {stockCardCode && (
                <>
                    <QuoteDialog
                        stockCardCode={stockCardCode}
                        configuratorFieldValues={form.watch()}
                        locked={watchLocked}
                        open={quoteDialogOpen}
                        onClose={() => {
                            setQuoteDialogOpen(false);
                        }}
                        onQuoteSubmitSuccess={onQuoteSubmitSuccess}
                        setPreviewLink={setPreviewLink}
                    />
                </>
            )}
        </>
    );
};

export default Configurator;
