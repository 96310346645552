import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SnackbarProvider} from 'notistack';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';

const theme = createTheme({
    palette: {
        primary: {
            main: '#44667d',
        },
        secondary: {
            main: '#0c71c3',
        },
    },
});
const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const queryClient = new QueryClient();

const root = createRoot(container);
root.render((
    <StrictMode>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider maxSnack={1}>
                    <CssBaseline/>
                    <App/>
                </SnackbarProvider>
            </QueryClientProvider>
        </ThemeProvider>
    </StrictMode>
));
