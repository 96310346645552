import {RhfAutocomplete} from 'mui-rhf-integration';
import {useEffect, useMemo} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import type {ConfiguratorFieldValues} from '@/pages/Configurator/Configurator';
import {useStockCardPropertyValuesQuery} from '@/queries/stock-card';
import {propertyNames} from '@/types/stock-card';
import type {PropertyName, Cad} from '@/types/stock-card';
import type {NoneValue} from '@/utils/stock-card-property';
import {isNone, noneValue} from '@/utils/stock-card-property';

type Props = {
    label : string;
    productLineCode : string;
    unlocked : boolean;
    selectedProperties : Partial<Record<PropertyName, string | null>>;
    name : PropertyName;
    form : UseFormReturn<ConfiguratorFieldValues>;
    previewLink : Cad | null;
    setPreviewLink : (previewLink : Cad | null) => void;
};

type Option = {
    label : string;
    value : string | NoneValue;
};

export const clearableProperties : PropertyName[] = ['size', 'assemblyPosition', 'faceSize', 'mountBase', 'outFlange'];
const potentiallyNumericProperties : PropertyName[] = ['size', 'ratio'];

const comparePotentiallyNumericOptions = (a : Option, b : Option) => {
    if (isNone(a.value)) {
        return -1;
    }

    if (isNone(b.value)) {
        return 1;
    }

    const aNumeric = parseFloat(a.value);
    const bNumeric = parseFloat(b.value);

    if (isNaN(aNumeric) || isNaN(bNumeric)) {
        return a.value.localeCompare(b.value);
    }

    return aNumeric - bNumeric;
};

const PropertySelect = ({
    form,
    label,
    productLineCode,
    unlocked,
    selectedProperties,
    name,
    previewLink,
    setPreviewLink,
} : Props) : JSX.Element => {
    const valuesQuery = useStockCardPropertyValuesQuery(productLineCode, name, unlocked, selectedProperties);
    const options = useMemo(() => {
        if (!valuesQuery.data) {
            return [];
        }

        const options : Option[] = valuesQuery.data.map(value => ({value: value ?? noneValue, label: value ?? 'N/A'}));

        if (potentiallyNumericProperties.includes(name)) {
            options.sort(comparePotentiallyNumericOptions);
        }

        return options;
    }, [valuesQuery.data, name]);

    useEffect(() => {
        if (valuesQuery.data && valuesQuery.data.length === 0) {
            form.setValue(name, null);
        }
    }, [valuesQuery.data, form.setValue]);

    useEffect(() => {
        const subscription = form.watch((value, {name: fieldName}) => {
            if (fieldName === name) {
                form.clearErrors(name);
            }

            if (unlocked || fieldName !== name) {
                return;
            }

            let currentReached = false;

            for (const property of propertyNames) {
                if (!currentReached) {
                    if (property === name) {
                        currentReached = true;
                    }

                    continue;
                }

                form.resetField(property);
                form.clearErrors(property);

                if (previewLink) {
                    setPreviewLink(null);
                }
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [form.watch, form.resetField, form.clearErrors, unlocked, name]);

    return (
        <RhfAutocomplete
            textFieldProps={{label}}
            options={options}
            loading={valuesQuery.isLoading}
            control={form.control}
            name={name}
            disableClearable={!unlocked || !clearableProperties.includes(name)}
            disabled={valuesQuery.data && valuesQuery.data.length === 0}
            optionToValue={option => option.value}
            valueToOption={value => options.find(option => option.value === value) ?? null}
        />
    );
};

export default PropertySelect;
