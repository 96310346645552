import HelpIcon from '@mui/icons-material/Help';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
} from '@mui/material';
import {RhfSwitch} from 'mui-rhf-integration';
import {useEffect, useState} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import type {ConfiguratorFieldValues} from '@/pages/Configurator/Configurator';
import {clearableProperties} from '@/pages/Configurator/PropertySelect';
import {propertyNames} from '@/types/stock-card';

type Props = {
    form : UseFormReturn<ConfiguratorFieldValues>;
    disabled : boolean;
};

const LockedSwitch = ({form, disabled} : Props) : JSX.Element => {
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    useEffect(() => {
        const subscription = form.watch((value, {name}) => {
            if (name !== 'locked') {
                return;
            }

            for (const property of propertyNames) {
                if (!value.locked && clearableProperties.includes(property)) {
                    form.setValue(property, null);
                    continue;
                }

                form.resetField(property);
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [form.watch, form.setValue, form.resetField]);

    return (
        <Stack spacing={2} direction="row" sx={{alignItems: 'center'}}>
            <FormControlLabel
                disabled={disabled}
                control={<RhfSwitch color="error" control={form.control} name="locked"/>}
                label="Locked"
            />
            <IconButton onClick={() => {
                setHelpDialogOpen(true);
            }}>
                <HelpIcon fontSize="medium"/>
            </IconButton>
            <Dialog
                open={helpDialogOpen}
                onClose={() => {
                    setHelpDialogOpen(false);
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Locked / Unlocked Configurator
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Our product configurator allows users to select product configurations
                        matching one of our 1600+ existing Stock Cards. By unlocking the configuration options,
                        users can also build a custom product offering and complete a new Stock Card Request.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setHelpDialogOpen(false);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default LockedSwitch;
