import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import {apiUrl} from '@/utils/api';

type ProductProperties = {
    model : string;
    size : string | null;
    ratio : string;
    assemblyPosition : string | null;
    faceSize : string | null;
    mountBase : string | null;
    outFlange : string | null;
};

type Address = {
    addressLineOne : string;
    addressLineTwo : string;
    city : string;
    state : string;
    zipCode : string;
};

export type CreateQuoteValues = {
    productLineCode : string;
    quantity : number;
    contact : {
        name : string;
        emailAddress : string;
    };
    companyName : string;
    billingAddress : Address;
    shippingAddress ?: Address;
    horsepowerSpecifications : string;
    torqueSpecifications : string;
    additionalInformation : string;
    productProperties : ProductProperties;
    submissionType : 'quote' | 'stock-card';
};

export const useCreateQuoteMutation = () : UseMutationResult<void, unknown, CreateQuoteValues> => {
    return useMutation(async (quoteValues : CreateQuoteValues) => {
        const response = await fetch(apiUrl('/quotes').toString(), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(quoteValues),
        });

        if (!response.ok) {
            throw new Error('Unable to submit quote');
        }
    });
};
