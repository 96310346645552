import {Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import type {UseFormReturn} from 'react-hook-form';
import type {QuoteFieldValues} from '@/pages/Configurator/QuoteDialog/QuoteDialog';

type Props = {
    prefix : 'shippingAddress' | 'billingAddress';
    form : UseFormReturn<QuoteFieldValues>;
};

const AddressFieldset = ({prefix, form} : Props) : JSX.Element => {
    return (
        <>
            <RhfTextField
                control={form.control}
                label="Address"
                name={`${prefix}.addressLineOne`}
            />
            <RhfTextField
                control={form.control}
                label="Address Line 2"
                name={`${prefix}.addressLineTwo`}
            />
            <RhfTextField
                control={form.control}
                label="City"
                name={`${prefix}.city`}
            />
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <RhfTextField
                    control={form.control}
                    sx={{flexGrow: '1'}}
                    label="State"
                    name={`${prefix}.state`}
                />
                <RhfTextField
                    control={form.control}
                    sx={{width: {xs: 'auto', sm: '200px'}}}
                    label="ZIP Code"
                    name={`${prefix}.zipCode`}
                />
            </Stack>
        </>
    );
};

export default AddressFieldset;
